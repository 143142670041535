import React from "react";
import { useState } from "react";
import { useRef } from "react";
import img2 from "./images/testimonial/2.jpeg";
import img3 from "./images/testimonial/3.jpeg";
import img4 from "./images/testimonial/4.jpeg";
import img5 from "./images/testimonial/5.jpeg";
import img6 from "./images/testimonial/6.jpeg";
import img7 from "./images/testimonial/7.jpeg";
import img8 from "./images/testimonial/8.jpeg";
import img9 from "./images/testimonial/9.jpeg";
import img10 from "./images/testimonial/10.jpeg";
import img11 from "./images/testimonial/11.jpeg";
import img12 from "./images/testimonial/12.jpeg";
import img13 from "./images/testimonial/13.jpeg";
import img14 from "./images/testimonial/14.jpeg";
import img15 from "./images/testimonial/15.jpeg";
import img16 from "./images/testimonial/16.jpeg";

import "./testimonial.css";

export const Testimonials = () => {
  const [expandedImage, setExpandedImage] = useState(null);
  const carouselRef = useRef(null);

  const data = [
    { id: 2, imgSrc: img2, caption: "A little blurb about this." },
    { id: 3, imgSrc: img3, caption: "And this too." },
    { id: 4, imgSrc: img4, caption: "And maybe this as well." },
    { id: 5, imgSrc: img5, caption: "And maybe this as well." },
    { id: 6, imgSrc: img6, caption: "And maybe this as well." },
    { id: 7, imgSrc: img7, caption: "And maybe this as well." },
    { id: 8, imgSrc: img8, caption: "And maybe this as well." },
    { id: 9, imgSrc: img9, caption: "And maybe this as well." },
    { id: 10, imgSrc: img10, caption: "And maybe this as well." },
    { id: 11, imgSrc: img11, caption: "And maybe this as well." },
    { id: 12, imgSrc: img12, caption: "And maybe this as well." },
    { id: 13, imgSrc: img13, caption: "And maybe this as well." },
    { id: 14, imgSrc: img14, caption: "And maybe this as well." },
    { id: 15, imgSrc: img15, caption: "And maybe this as well." },
    { id: 16, imgSrc: img16, caption: "And maybe this as well." },
  ];

  const expandImage = (imgSrc) => {
    setExpandedImage(imgSrc);
  };

  const closeImage = () => {
    setExpandedImage(null);
  };

  const handleWheel = (e) => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += e.deltaY;
      e.preventDefault(); // Prevent default vertical scrolling behavior
    }
  };

  const handleMouseEnter = () => {
    // Disable vertical scrolling when mouse enters carousel area
    document.body.style.overflowY = "hidden";
  };

  const handleMouseLeave = () => {
    // Enable vertical scrolling when mouse leaves carousel area
    document.body.style.overflowY = "auto";
  };

  return (
    <>
      <h1 style={{ textAlign: "center", padding: "10px" }}>Testimonials</h1>
      <div
        id="carousel"
        ref={carouselRef}
        onWheel={handleWheel}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {data.map((item, index) => (
          <div className="testimonial_container" key={index}>
            <img
              className="img"
              src={item.imgSrc}
              alt={`Testimonial ${item.id}`}
              onClick={() => expandImage(item.imgSrc)}
            />
            <div className="overlay">
              <h3>{item.caption}</h3>
            </div>
          </div>
        ))}
      </div>
      {expandedImage && (
        <div id="expanded-image-overlay" onClick={closeImage}>
          <img className="expanded-img" src={expandedImage} alt="Expanded" />
          <div id="expanded-caption">
            <h3>
              {data.find((item) => item.imgSrc === expandedImage).caption}
            </h3>
          </div>
        </div>
      )}
    </>
  );
};
