import React, { useRef, useState } from "react";
import bgImg from "./images/gallery/13.jpg";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import { Alert, AlertIcon, Stack } from "@chakra-ui/react";

export default function Form() {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false); // Add state for error

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_e5ybfce", "template_gwvamgk", form.current, {
        publicKey: "HG9qEH267NB_s3EBa",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
          setSubmitted(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setError(true); // Set error state to true
        }
      );
  };

  return (
    <section>
      <div className="contact">
        <div className="register">
          <div className="col-1">
            <h1>Contact US</h1>
            <span></span>

            {/* <form id='form' className='flex flex-col' >
                    <input type="text" placeholder='user' />
                    <input type="text" placeholder='mobile number' />
                    <textarea name="message" rows="5" placeholder="Enter your message here"></textarea>
                    <button className='btn'>Sign In</button>
                </form> */}

            <form
              ref={form}
              id="form"
              className="flex flex-col"
              onSubmit={sendEmail}
            >
              {/* <label>Name</label> */}
              <input type="text" placeholder="name" />
              {/* <label>Mobile Number</label> */}
              <input type="text" placeholder="mobile number" />
              {/* <label>Message</label> */}
              <textarea
                name="message"
                rows="5"
                placeholder="Enter your message here"
                style={{ border: "1px solid #E2DFD2 ", padding:"16px" }}
              ></textarea>
              <button className="btn" style={{backgroundColor:"#008e87"}}>
                Send
                <input type="submit" value="" />
              </button>
            </form>
            {submitted && (
              <Stack spacing={3}>
                {" "}
                {/* Wrap the Alert components in a Stack */}
                <Alert status="success">
                  <AlertIcon />
                  Message sent successfully
                </Alert>
              </Stack>
            )}
            {error && (
              <Stack spacing={3}>
                {" "}
                {/* Wrap the Alert components in a Stack */}
                <Alert status="error">
                  <AlertIcon />
                  Failed to send message
                </Alert>
              </Stack>
            )}
          </div>
          <div className="col-2">
            <img src={bgImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
