import React from "react";
import "./Home.css";
import potrait from "./images/homepage/potrait_circle.png";
import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Box,
  Flex
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


const Home = () => {
  const navigate = useNavigate();

  const cardData = [
    {
      title: "Parenting & Child Behaviour Coach",
      description: "Practical strategies to enrich the home environment, manage behaviour, deal with aggression.",
      buttonLabel: "View here",
    },
    {
      title: "Soft Skills & Etiquette Training",
      description: "Communication and language skills, teamwork, leadership skills, time management",
      buttonLabel: "View here",
    }
  ];

  const handleButtonClick = () => {
    navigate("/services");
  };

  return (
    <>
      <div className="container">
        <div className="text" >

          <div className="title" >
            STEPPINGSTONESSOLUTIONS
          </div>

          <div className="subt" style={{ display: 'flex', flexDirection: 'column', marginBottom: '30px' }}>
            <span className="sub">Guide.</span>
            <span className="sub">Enable.</span>
            <span className="sub">Empower.</span>
          </div>
          <div className="designation" style={{ lineHeight: 1.1 }}>
            <div className="" style={{ border: "1px solid black", background: 'black', height: "5px", width: '150px' }}></div>
            <br />
            <div className="name"  >
              Kamal Patel
            </div>
            <div className="subname">
              Founder & Director
            </div>
          </div>

        </div>


        <div className="image">
          <img src={potrait} alt="" />
        </div>
      </div>
      <div className="label">
        <span>About us</span>
      </div>
      <div className="about_us">
        <div className="inner">
          <Card boxShadow="lg">
            <CardBody>
              <Text fontSize={["sm", "md", "lg"]}>
                Since 2000, Kamal has been conducting workshops
                for parents and teachers, delving into various
                aspects of child development and early educational
                practices. Her dedication to sharing knowledge
                and empowering others has earned her invitations
                to prominent educational institutions. She
                has conducted personality development sessions
                for teachers and workshops on parenting skills
                and child behaviour for Navrachana Higher
                Secondary School (NHSS), Cygnus World School,
                Harni, Navrachana International School Vadodara (NISV),
                D. R. Amin School, Vadodara, and Mayoor
                Private School (MPS), Abudhabi, Humm Care,
                among others. Through her personalized
                consultations and workshops, Kamal has
                positively impacted numerous young parents,
                helping them develop effective parenting
                practices and make informed decisions
                concerning their children. Her invaluable
                guidance and counseling have garnered high
                praise from satisfied clients.

              </Text>
              <br></br>
              <Text fontSize={["sm", "md", "lg"]}>
                Since 2005, she has also been an esteemed freelance
                trainer, collaborating with notable corporates
                and institutions to deliver comprehensive
                training in soft skills, etiquette, English
                language enrichment, communication skills,
                and grooming. Her outstanding contributions
                have been acknowledged by organizations
                such as Navrachana University, Navrachana
                International School, Air Hostess Academy,
                Inner I Consultants, and Infuse Management
                Consultants.

                Kamal's areas of expertise lie in English
                Language Enrichment, Communication Skills
                Training, Business & Social Etiquette Practices,
                Soft Skills Training, Personality Development,
                and Grooming.

              </Text>
              <br />
              <Text fontSize={["sm", "md", "lg"]}>
                With a passion for writing, Kamal has honed her
                content writing and editing skills over the past
                two decades. Currently, she is associated as a
                parenting expert, content writer, and editor
                with HUMM CARE, a renowned digital healthcare
                platform offering wellness services for parents
                and children. Her invaluable contributions
                enhance the platform's offerings and provide
                valuable insights to its users. Kamal's expertise
                has earned her recognition as a keynote speaker
                at a webinar organized by Dr. KK Agarwal's
                Heart Care Foundation of India (HCFI).
                The webinar focused on ‘Building Communication
                Skills in Children’, highlighting Kamal's deep understanding of effective communication strategies for young individuals. Stepping Stones Solutions represents a natural progression for Kamal, allowing her to continue her impactful journey as a parenting coach, counselor, and soft skills trainer. With her vast experience and dedication to personal and professional growth, Kamal remains committed to empowering individuals and fostering positive development in the realm of parenting and soft skills training.
              </Text>
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="label">
        <span>Our Services</span>
      </div>
      <div className="services">
        {cardData.map((data, index) => (
          <Card key={index} className="card">
            <CardHeader>
              <Heading size="md">{data.title}</Heading>
            </CardHeader>
            <CardBody>
              <Text>{data.description}</Text>
            </CardBody>
            <CardFooter>
              <Button onClick={handleButtonClick} style={{ backgroundColor: "#D0E8ED" }}>{data.buttonLabel}</Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </>
  );
};

export default Home;
