import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from "@chakra-ui/react";
import img12 from "./images/gallery/12.jpg";
import img30 from "./images/gallery/30.jpg";
import "./services.css"

const Services = () => {
  const data = [
    {
      imageUrl: img12,
      title: "Parenting & Child Behaviour Coach",
      description: `Over 25 years, our individual counselling sessions and customised workshops have helped more than 3000 young parents develop effective parenting practices and make informed decisions regarding their children. 
      Always sensitive to parents’ concerns, we help to identify issues and offer suggestions or recommend remedial assistance, if necessary.
      We equip parents with practical strategies to enrich the home environment, manage behaviour, deal with anger and aggression, manage sibling rivalry, to name a few.
      We make parents aware of the importance of fostering an environment of mutual respect, good communication and strong bonding.
      Our enduring objective is to help parents raise happy, healthy children with a high self-esteem`,
      list: [
        "Early Brain Development",
        "Enriching the Early Home Environment",
        "Promoting Language & Reading",
        "Gadget & Screen Addiction",
        "Sibling Rivalry",
        "Parenting & Work-Life Balance",
        "Dealing with the Stubborn Child",
        "Anger & Aggression In Children",
        "Making Children Independent & Resilient",
        "Behaviour Management",
        "Tantrum Management",
        "Consequences & Punishments",
        "When, Why & How To Say ‘No’",
        "Negotiable & Non-Negotiable Behaviour",
        "Importance of Time & Attention",
        "The Importance Of Schedules And Routines",
        "Effects of Stress on Behaviour",
        "Building Emotional Intelligence",
        "Separation Anxiety",
        "Managing Mealtimes",
        "The Pampered/Over-Indulged Child",
        "Why Kids Lie?",
        "Nutrition & Exercise",
        "Anger in Parents",
        "Parenting Styles",
        "Parents as Role Models",
        "Realistic Expectations from the Child’s School"
      ]
    },
    {
      imageUrl: img30,
      title: "Soft Skills & Etiquette Training",
      description: `Our endeavour is to empower individuals with the soft skills and interpersonal competencies necessary to achieve professional and personal growth.
        These include communication and language skills, teamwork, leadership skills, time management, problem solving, to name a few.
        Our goal is to help you build awareness and sensitivity to different cultures and identities and equip you with the skills necessary to traverse a dynamic landscape.`,
      list: [
        "Good Manners & Courtesy",
        "Communication Skills (Verbal & Non-Verbal)",
        "Presentation Skills (Public Speaking & Voice Modulation)",
        "English Language Enrichment",
        "Punctuality and Time Management",
        "Leadership Skills",
        "Ethical Behaviour",
        "Team Work & Collaboration",
        "Gender Sensitisation",
        "Anger Management",
        "Building Social Skills",
        "Problem Solving / Conflict Management",
        "Grooming and Image Enhancing",
        "Personal Hygiene",
        "Dining Etiquette",
        "Bathroom Etiquette",
        "Classroom Etiquette",
        "Workplace Etiquette",
        "Building Emotional Intelligence"
      ]
    }
  ];
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Collapse if clicked again
    } else {
      setExpandedIndex(index); // Expand if clicked
    }
  };

  return (
    <>
      <div className="wrapper">
        <h1 style={{ textAlign: "center", padding: "10px" }}>Services</h1>

        <div className="main" style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
          {data.map((item, index) => (
            <Box
              key={index}
              p={8}
              my={8}
              shadow="md"
              borderWidth="1px"
              borderRadius="xl"
              width="90vw"
            >
              {/* <Flex> */}
              <div id="sercard" >
                <Box id="serimg">
                  <Image
                    src={item.imageUrl}
                    alt="Image"
                    w={250}
                    h={250}
                    borderRadius="md"
                  />
                </Box>
                <VStack id="sertext" align="start" justify="center" flex="1" mx={14}>
                  <Text fontSize={["lg", "lg", "xl"]} fontWeight="bold">
                    {item.title}
                  </Text>
                  <Text>{item.description}</Text>
                </VStack>
                {/* </Flex> */}
              </div>
              <br />
              <Accordion allowToggle width="100%">
                <AccordionItem isFocusable={false}>
                  <h2>
                    <AccordionButton onClick={() => handleAccordionClick(index)}>
                      <Box flex="1" textAlign="left">
                        Services Offered
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} isFocusable={false} isOpen={expandedIndex === index}>
                    <VStack align="start">
                      {item.list.map((data, i) => (
                        <Box key={i} display="flex" alignItems="start">
                          <Box as="span" pr={2}>&#8226;</Box> {/* Bullet point */}
                          <Text>{data}</Text>
                        </Box>
                      ))}
                    </VStack>
                  </AccordionPanel>

                </AccordionItem>
              </Accordion>
            </Box>
          ))}
        </div>
      </div >
    </>
  );
};

export default Services;