import {
  useDisclosure,
  Flex,
  Button,
  VStack,
  Icon,
  Slide,
  Heading,
} from "@chakra-ui/react";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { NavLink } from "react-router-dom";
import React from "react";
import { data } from "./data";

export default function MobileDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Flex alignItems="center">
      <Button ref={btnRef} onClick={onOpen} variant="unstyled">
        <Icon as={IoMdMenu} boxSize={6} />
      </Button>

      <Slide direction="right" in={isOpen} style={{ zIndex: 10 }}>
        <VStack
          pos="fixed"
          top={0}
          right={0}
          h="100vh"
          w="70%"
          maxW="300px"
          bg="#d0e8ed"
          spacing={4}
          alignItems="center"
          p={4}
        >
          <Button onClick={onClose} variant="unstyled" alignSelf="flex-end">
            <Icon as={IoMdClose} boxSize={6} />
          </Button>

          {/* <Heading as="h2" size="md" color="white">
            Menu
          </Heading> */}

          {data.map((item, i) => (
            <NavLink to={item.link} onClick={onClose} key={i}>
              <Button variant="ghost"> {item.label} </Button>
            </NavLink>
          ))}
        </VStack>
      </Slide>
    </Flex>
  );
}
