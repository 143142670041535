import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Contact from "./Contact";
import Footer from "./Footer";
import { Gallery } from "./Gallery";
import Header from "./Header";
import Home from "./Home";
import { Testimonials } from "./Testimonial";
import Services from "./Services";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div
          className="content"
          style={{
            minHeight: "calc(100vh - 250px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/testimonial" element={<Testimonials />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
