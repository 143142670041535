import {
  Image,
  Flex,
  Button,
  HStack,
  chakra,
  useBreakpointValue,
} from "@chakra-ui/react";
import Logo from "./images/Logo.png";
import { NavLink } from "react-router-dom";
import React from "react";
import MobileDrawer from "./MobileDrawer";
import { data } from "./data";

export default function Header() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <chakra.header id="header" style={{ background: "#D0E8ED" }}>
      <Flex w="100%" px="5" py="6" align="center" justify="space-between">
        <NavLink to="/">
          <HStack>
            <Image src={Logo} h="32px" />
          </HStack>
        </NavLink>

        {isMobile ? (
          <HStack>
            <MobileDrawer />
          </HStack>
        ) : (
          <HStack as="nav" spacing="5" >
            {data.map((item, i) => (
              <NavLink to={item.link} key={i}>
                <Button variant="nav"> {item.label} </Button>
              </NavLink>
            ))}
          </HStack>
        )}
      </Flex>
    </chakra.header>
  );
}
