import React, { useState } from "react";
import img1 from "./images/gallery/1.jpg";
import img2 from "./images/gallery/2.jpg";
import img3 from "./images/gallery/3.jpg";
import img4 from "./images/gallery/4.jpg";
import img5 from "./images/gallery/5.jpg";
import img6 from "./images/gallery/6.jpg";
import img7 from "./images/gallery/7.jpg";
import img8 from "./images/gallery/8.jpg";
import img9 from "./images/gallery/9.jpg";
import img10 from "./images/gallery/10.jpg";
import img12 from "./images/gallery/12.jpg";
import img13 from "./images/gallery/13.jpg";
import img14 from "./images/gallery/14.jpg";
import img15 from "./images/gallery/15.jpg";
import img16 from "./images/gallery/16.jpg";
import img17 from "./images/gallery/17.jpg";
import img18 from "./images/gallery/18.jpg";
import img19 from "./images/gallery/19.jpg";
import img20 from "./images/gallery/20.jpg";
import img21 from "./images/gallery/21.jpg";
import img22 from "./images/gallery/22.jpg";
import img23 from "./images/gallery/23.jpg";
import img24 from "./images/gallery/24.jpg";
import img25 from "./images/gallery/25.jpg";
import img26 from "./images/gallery/26.jpg";
import img27 from "./images/gallery/27.jpg";
import img28 from "./images/gallery/28.jpg";
import img29 from "./images/gallery/29.jpg";

import "./gallery.css";
import { CloseIcon } from "@chakra-ui/icons";
import { Flex } from "react-landing-page";

export const Gallery = () => {
  let data = [
    {
      id: 1,
      imgSrc: img1,
      title: "",
    },
    {
      id: 2,
      imgSrc: img2,
      title: "",
    },
    {
      id: 3,
      imgSrc: img3,
      title: "",
    },
    {
      id: 4,
      imgSrc: img4,
      title: "",
    },
    {
      id: 5,
      imgSrc: img5,
      title: "",
    },
    {
      id: 6,
      imgSrc: img6,
      title: "",
    },
    {
      id: 7,
      imgSrc: img7,
      title: "",
    },
    {
      id: 9,
      imgSrc: img9,
      title: "",
    },
    {
      id: 10,
      imgSrc: img10,
      title: "",
    },
    {
      id: 12,
      imgSrc: img12,
      title: "",
    },
    {
      id: 13,
      imgSrc: img13,
      title: "",
    },
    {
      id: 14,
      imgSrc: img14,
      title: "",
    },
    {
      id: 15,
      imgSrc: img15,
      title: "",
    },
    {
      id: 16,
      imgSrc: img16,
      title: "",
    },
    {
      id: 17,
      imgSrc: img17,
      title: "",
    },
    {
      id: 18,
      imgSrc: img18,
      title: "",
    },
    {
      id: 19,
      imgSrc: img19,
      title: "",
    },
    {
      id: 20,
      imgSrc: img20,
      title: "",
    },
    {
      id: 21,
      imgSrc: img21,
      title: "",
    },
    {
      id: 22,
      imgSrc: img22,
      title: "",
    },
    {
      id: 23,
      imgSrc: img23,
      title: "",
    },
    {
      id: 26,
      imgSrc: img26,
      title: "",
    },
    {
      id: 27,
      imgSrc: img27,
      title: "",
    },
    {
      id: 28,
      imgSrc: img28,
      title: "",
    },
    {
      id: 29,
      imgSrc: img29,
      title: "",
    },
  ];

  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");
  const [tempTitle, setTempTitle] = useState(""); // State to store the title

  const getImg = (imgSrc, title) => {
    setTempImgSrc(imgSrc);
    setTempTitle(title); // Set the title in the state
    setModel(true);
  };

  return (
    <>
      <h1 style={{ textAlign: "center", padding: "10px" }}>Gallery</h1>
      <div className={model ? "model open" : "model"}>
        <img src={tempImgSrc} />
        <p>{tempTitle}</p> {/* Display the title */}
        <CloseIcon
          onClick={() => {
            setModel(false);
          }}
        />
      </div>
      <div className="gallery">
        {data.map((item, index) => {
          return (
            <div
              className="pics"
              key={index}
              onClick={() => getImg(item.imgSrc, item.title)} // Pass title to getImg function
              data-title={item.title} // Add data-title attribute with the title
            >
              <img src={item.imgSrc} style={{ width: "100%" }} />
            </div>
            // <div
            //   className="pics"
            //   key={index}
            //   onClick={() => getImg(item.imgSrc, item.title)}
            //   data-title={item.title}
            //   style={{ backgroundImage: `url(${item.imgSrc})` }} // Set background image
            // />
          );
        })}
      </div>
    </>
  );
};
