import { Image, Text } from "@chakra-ui/react";
import Logo from "./images/Logo.png";
import { NavLink } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon
import { Flex, Box, IconButton, Icon } from "@chakra-ui/react";
import "./footer.css";

const Footer = () => {
  return (
    <div
      className="footer"
      style={{
        width: "100%",
        height: "250px",
        // border: "1px solid black",
        display: "flex",
        backgroundColor: "#D0E8ED",
      }}
    >
      <div id="left_footer" className="left">
        <NavLink to="/">
          <Image id="footer_logo" src={Logo} alt="steppingstonessolutions" />
        </NavLink>
        <Text id="footer_text" mt={2} color="black" fontSize="lg">
          Guide. Enable. Empower.
        </Text>
        <Text id="footer_text" mt={2} color="black" fontSize="md">
          +91-9898247937 <br />
          ckamalhp@gmail.com
        </Text>
      </div>
      <div id="right_footer" className="right">
        <div id="quick" className="quick_links">
          {" "}
          <Text
            className="link"
            fontSize="lg"
            style={{ marginBottom: "20px", fontWeight: "bold" }}
          >
            Quick Links
          </Text>
          <NavLink to="/gallery">
            <Text
              className="link"
              fontSize="md"
              style={{ marginBottom: "10px" }}
            >
              Gallery
            </Text>
          </NavLink>
          <NavLink to="/services">
            <Text
              className="link"
              fontSize="md"
              style={{ marginBottom: "10px" }}
            >
              Services
            </Text>
          </NavLink>
          <NavLink to="/testimonial">
            <Text
              className="link"
              fontSize="md"
              style={{ marginBottom: "10px" }}
            >
              Testimonials
            </Text>
          </NavLink>
          <NavLink to="/contact">
            <Text
              className="link"
              fontSize="md"
              style={{ marginBottom: "10px" }}
            >
              Contact
            </Text>
          </NavLink>
        </div>
        <div id="socials" className="socials">

          <a href="https://www.facebook.com/profile.php?id=100009344879902&mibextid=qi2Omg&rdid=9WXofiNBEkeOVfSP" target="_blank" rel="noopener noreferrer">
            <Box>
              <IconButton
                aria-label="Facebook"
                icon={<Icon as={FaFacebook} boxSize={7} />}
                variant="ghost"
                colorScheme="blue"
                size='lg'
              />

            </Box>
          </a>
          <a href="https://www.linkedin.com/in/kamal-patel-322b53214/" target="_blank" rel="noopener noreferrer">
            <Box>
              <IconButton
                aria-label="LinkedIn"
                icon={<Icon as={FaLinkedin} boxSize={7} />}
                variant="ghost"
                colorScheme="blue"
                size="lg"
              />
            </Box>
          </a>

          <a href="https://www.instagram.com/steppingstonessolutions.s3/" target="_blank" rel="noopener noreferrer">
            <Box>
              <IconButton
                aria-label="Instagram"
                icon={<Icon as={FaInstagram} boxSize={7} />}
                variant="ghost"
                colorScheme="blue"
                size="lg"
              />
            </Box>
          </a>

          {/* WhatsApp link */}
          <a href="https://wa.me/919898247937" target="_blank" rel="noopener noreferrer">
            <Box>
              <IconButton
                aria-label="WhatsApp"
                icon={<Icon as={FaWhatsapp} boxSize={7} />}
                variant="ghost"
                colorScheme="blue" // Use appropriate color scheme
                size="lg"
              />
            </Box>
          </a>

        </div>
      </div>
    </div>
  );
};

export default Footer;
